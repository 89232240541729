var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4"},[(_vm.reportLoading)?_c('div',[_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":15}})],1)],1):_c('div',[(_vm.solutionId === null)?_c('div',[_c('div',{staticClass:"overlay-text"},[_vm._v(" No Solutions found. ")]),_c('div',{staticClass:"blurred-report blur-img-container mt-2"})]):_c('div',[(_vm.isSolutionSelected)?_c('div',{staticClass:"market-report-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{},[_c('div',{staticClass:"d-flex align-items-baseline report-title"},[_c('div',{staticClass:"pr-2"},[_vm._v("Technographic -")]),_c('div',[_c('multiselect',{staticClass:"min-width-20 cursor-pointer dc-report-select market_report",attrs:{"track-by":"id","label":"displayText","allowEmpty":false,"showLabels":false,"options":_vm.getTechnographicsOptions,"searchable":false,"id":"technographic","value":_vm.selectedSolution},on:{"select":_vm.updateTechnographic},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(option.name)+" ")])])]}}],null,false,244273858)})],1)])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 report-subtitle "},[_c('div',{},[_vm._v(" "+_vm._s(_vm.reportSubTitle)+" ")])]),(this.selectedFiltersMessage.trim() !== "")?_c('div',{staticClass:"col-sm-6 text-right selected-filters-message"},[_vm._m(0),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(this.selectedFiltersMessage))])]):_vm._e()])]):_vm._e()]),(_vm.isSolutionSelected)?_c('div',{staticClass:"market-report-container"},[_c('div',{staticClass:"table-responsive landscape-trend-table"},[_c('table',{staticClass:"table table-striped small-font-for-print "},[(_vm.regulatedTableData.length)?_c('thead',[_c('tr',[_c('th',{staticClass:"rank-col",attrs:{"width":"2%"}},[_vm._v("Rank")]),_c('th',{attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('vendor')}}},[_c('span',[_vm._v("Vendor Name")]),_c('span',[_c('i',{class:[
                      'fa fa-regular sort-icon',
                      _vm.getSortIconClass('vendor')
                    ]})])]),(_vm.showProduct)?_c('th',{attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('product')}}},[_c('span',[_vm._v("Product Name")]),_c('span',[_c('i',{class:[
                      'fa fa-regular sort-icon',
                      _vm.getSortIconClass('product')
                    ]})])]):_vm._e(),_c('th',{staticClass:"text-right",on:{"click":function($event){return _vm.sortByColumn('market_measure')}}},[_c('span',[_vm._v("Market Total")]),_c('span',[_c('i',{class:[
                      'fa fa-regular sort-icon',
                      _vm.getSortIconClass('market_measure')
                    ]})])]),_vm._l((_vm.customHeaders),function(column){return _c('th',{key:column,staticClass:"text-right",on:{"click":function($event){return _vm.sortByColumn(column)}}},[_c('span',[_vm._v(" "+_vm._s(column))]),_c('span',[_c('i',{class:[
                      'fa fa-regular sort-icon',
                      _vm.getSortIconClass(column)
                    ]})])])})],2)]):_vm._e(),(_vm.regulatedTableData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.regulatedTableData),function(data,rank){return _c('tr',{key:rank},[_c('td',{staticClass:"rank-col"},[_vm._v(" "+_vm._s(rank + 1)+" ")]),_c('td',{staticClass:"name-column",attrs:{"title":data.vendor}},[_vm._v(" "+_vm._s(data.vendor)+" ")]),(_vm.showProduct)?_c('td',{staticClass:"name-column",attrs:{"title":data.product}},[_vm._v(" "+_vm._s(data.product)+" ")]):_vm._e(),(_vm.showLink(data))?_c('td',{staticClass:"text-right"},[_c('router-link',{staticClass:"cursor-pointer product-link",attrs:{"to":{
                    name: 'ProductReport',
                    params: { id: data.product_id },
                    query: _vm.filterQuery(data)
                  }}},[_vm._v(" "+_vm._s(_vm._f("numberFormat")(data.market_measure))+" ")])],1):_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("numberFormat")(data.market_measure))+" ")]),_vm._l((_vm.customHeaders),function(header,index){return _c('td',{key:index,staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.metric_values[header][0],data.metric_values[header][1]))+" ")])})],2)}),_c('tr',{staticClass:"report-footer"},[_c('td',{staticClass:"rank-col"}),_c('td',{attrs:{"colspan":_vm.showProduct ? 2 : 1}},[_vm._v("Grand Total")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("numberFormat")(parseFloat(_vm.reportTotal.market_measure)))+" ")]),_vm._l((_vm.customHeaders),function(header){return _c('td',{key:header,staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.metric_values[header][0],_vm.reportTotal.metric_values[header][1]))+" ")])})],2)],2):_c('tbody',[_vm._m(1)])])]),_c('landscape-mode')],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"font-weight-bold text-muted"},[_c('i',{staticClass:"fa fa-filter",attrs:{"aria-hidden":"true"}}),_vm._v(" Filters:")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])}]

export { render, staticRenderFns }