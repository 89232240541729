<template>
  <div class="px-4">
    <!-- <b-card class="page-card"> -->
    <div v-if="reportLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="15"></content-placeholders-text>
      </content-placeholders>
    </div>
    <div v-else>
      <div v-if="solutionId === null">
        <div class="overlay-text">
          No Solutions found.
        </div>
        <div class="blurred-report blur-img-container mt-2"></div>
      </div>

      <div v-else>
        <div class="market-report-header" v-if="isSolutionSelected">
          <div class="row">
            <div class="col-sm-12">
              <div class="">
                <div class="d-flex align-items-baseline report-title">
                  <div class="pr-2">Technographic -</div>
                  <div>
                    <multiselect
                      track-by="id"
                      label="displayText"
                      :allowEmpty="false"
                      :showLabels="false"
                      :options="getTechnographicsOptions"
                      :searchable="false"
                      class="min-width-20 cursor-pointer dc-report-select market_report"
                      id="technographic"
                      :value="selectedSolution"
                      @select="updateTechnographic"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <div class="d-flex">
                          <div class="option-text">
                            {{ option.name }}
                          </div>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 report-subtitle ">
              <div class="">
                {{ reportSubTitle }}
              </div>
            </div>
            <div
              class="col-sm-6 text-right selected-filters-message"
              v-if="this.selectedFiltersMessage.trim() !== ``"
            >
              <span class="font-weight-bold text-muted">
                <i class="fa fa-filter" aria-hidden="true"></i>
                Filters:</span
              >
              <span class="pl-2">{{ this.selectedFiltersMessage }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="market-report-container" v-if="isSolutionSelected">
        <div class="table-responsive landscape-trend-table">
          <table class="table table-striped small-font-for-print ">
            <thead v-if="regulatedTableData.length">
              <tr>
                <th width="2%" class="rank-col">Rank</th>
                <th width="10%" @click="sortByColumn('vendor')">
                  <span>Vendor Name</span>
                  <span>
                    <i
                      :class="[
                        'fa fa-regular sort-icon',
                        getSortIconClass('vendor')
                      ]"
                    >
                    </i>
                  </span>
                </th>
                <th
                  width="10%"
                  @click="sortByColumn('product')"
                  v-if="showProduct"
                >
                  <span>Product Name</span>
                  <span>
                    <i
                      :class="[
                        'fa fa-regular sort-icon',
                        getSortIconClass('product')
                      ]"
                    >
                    </i>
                  </span>
                </th>
                <th @click="sortByColumn('market_measure')" class="text-right">
                  <span>Market Total</span>
                  <span>
                    <i
                      :class="[
                        'fa fa-regular sort-icon',
                        getSortIconClass('market_measure')
                      ]"
                    >
                    </i>
                  </span>
                </th>
                <th
                  @click="sortByColumn(column)"
                  v-for="column in customHeaders"
                  :key="column"
                  class="text-right"
                >
                  <span> {{ column }}</span>
                  <span>
                    <i
                      :class="[
                        'fa fa-regular sort-icon',
                        getSortIconClass(column)
                      ]"
                    >
                    </i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody ref="reportTableBody" v-if="regulatedTableData.length">
              <tr v-for="(data, rank) in regulatedTableData" :key="rank">
                <td class="rank-col">
                  {{ rank + 1 }}
                </td>
                <td class="name-column" :title="data.vendor">
                  {{ data.vendor }}
                </td>
                <td
                  v-if="showProduct"
                  class="name-column"
                  :title="data.product"
                >
                  {{ data.product }}
                </td>
                <td class="text-right" v-if="showLink(data)">
                  <router-link
                    :to="{
                      name: 'ProductReport',
                      params: { id: data.product_id },
                      query: filterQuery(data)
                    }"
                    class="cursor-pointer product-link"
                  >
                    {{ data.market_measure | numberFormat }}
                  </router-link>
                </td>
                <td class="text-right" v-else>
                  {{ data.market_measure | numberFormat }}
                </td>

                <td
                  class="text-right"
                  v-for="(header, index) in customHeaders"
                  :key="index"
                >
                  {{
                    data.metric_values[header][0]
                      | formatValue(data.metric_values[header][1])
                  }}
                </td>
              </tr>
              <tr class="report-footer">
                <td class="rank-col"></td>
                <td :colspan="showProduct ? 2 : 1">Grand Total</td>
                <td class="text-right">
                  {{ parseFloat(reportTotal.market_measure) | numberFormat }}
                </td>
                <td
                  v-for="header in customHeaders"
                  :key="header"
                  class="text-right"
                >
                  {{
                    reportTotal.metric_values[header][0]
                      | formatValue(reportTotal.metric_values[header][1])
                  }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <div class="text-center text-muted">No Records found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <landscape-mode></landscape-mode>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
// ui components
import Multiselect from 'vue-multiselect'

import LandscapeMode from '@/modules/core/components/LandscapeMode'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
// mixins
import ReportUtil from '../mixins/reportUtil'
export default {
  name: 'firmographics-report',
  components: {
    LandscapeMode,
    Multiselect
  },

  mixins: [ReportUtil],
  beforeCreate() {
    this.$nextTick(() => {
      this.getFirmographics()
    })
    this.$store.dispatch('setAside', true)
  },
  created() {
    this.customHeaders.forEach(key => {
      this.sortOrder[key] = ['none', 'metric']
    })
  },
  beforeDestroy() {
    document.body.classList.add('aside-menu-hidden')
    this.$store.dispatch('setAsideToogle', false)
  },
  mounted() {
    document.body.classList.remove('aside-menu-hidden')
    this.$store.dispatch('setAsideToogle', true)
    this.trackReportView()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  beforeUpdate() {
    this.customHeaders.forEach(key => {
      if (!this.sortOrder[key]) this.sortOrder[key] = ['none', 'metric']
    })
  },
  updated() {
    this.prepareDownloadReport()
  },
  data() {
    return {
      orderSolution: false,
      userSolutionLoaded: false,
      allSolutionLoaded: false,
      sortOrder: {
        vendor: ['none', 'string'],
        product: ['none', 'string'],
        market_measure: ['none', 'float'],
        market_percent: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapGetters(['showAside', 'isAsideOpen']),
    ...mapGetters('marketShareReport', [
      'assetMin',
      'assetMax',
      'selectedFiltersMessage',
      'reportSubTitle',
      'getTechnographicsOptions'
    ]),
    ...mapGetters('Ability', ['canPurchase']),
    ...mapState('marketShareReport', [
      'reports',
      'solutionId',
      'reportLoading',
      'topVendors',
      'showProduct',
      'marketMeasure',
      'fiType',
      'selectedVendorReport',
      'topVendorsMax',
      'reportData',
      'reportTotal',
      'reportOtherData',
      'bankServiceName'
    ]),
    selectedSolution() {
      return this.getTechnographicsOptions.find(
        opt => opt.id == this.solutionId
      )
    },
    isSolutionSelected() {
      return this.solutionId !== undefined
    },
    customHeaders() {
      return Object.keys(this.reportData[0].metric_values || {})
    },
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }
      return regulatedData
    }
  },
  methods: {
    ...mapMutations('marketShareReport', [
      'updateSolution',
      'updateFirmographicId',
      'setDownloadReportData'
    ]),
    ...mapActions('marketShareReport', ['getFirmographics']),
    showLink(data) {
      return (
        data.product_id !== null && this.showProduct && data.vendor !== 'Others'
      )
    },
    filterQuery(data) {
      let queryObj = {
        vendor_id: data.vendor_id,
        bank_service_id: this.solutionId
      }
      if (this.fiType !== 0) {
        queryObj.fi_type = this.fiType
      }
      if (this.assetMin !== null) {
        queryObj.asset_min = this.assetMin
      }
      if (this.assetMax !== null) {
        queryObj.asset_max = this.assetMax
      }
      return queryObj
    },
    updateTechnographic(selectedOption) {
      this.updateSolution(selectedOption.id)
      this.getFirmographics()
    },
    getMarketShare(bankId) {
      this.updateSolution(bankId)
      this.getFirmographics()
      this.trackReportView()
    },
    trackReportView() {
      this.$ahoy.track('report_view', {
        bank_service_id: this.solutionId || 'default',
        report_type: 'FinTech Market Reports',
        category: 'Firmographics'
      })
    },
    // graphPercentage(percentage) {
    //   let max = Math.max(
    //     ...this.regulatedTableData.map(x => parseFloat(x.market_percent))
    //   )
    //   return (parseFloat(percentage) * 100) / max + '%'
    // },

    // orderNewSolution() {
    //   this.orderSolution = true
    // },
    // closeOrderSolution(status) {
    //   this.orderSolution = false
    //   if (status === 'complete') {
    //     this.userSolutionLoaded = false
    //     this.allSolutionLoaded = false
    //     this.getFirmographics()
    //   }
    // },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Rank',
        'Vendor Name',
        { value: 'Market Total', format: { alignment: 'right' } },
        ...this.customHeaders
      ]
      if (this.showProduct) headers.splice(2, 0, 'Product Name')
      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]
      //Report data
      combinedData.map((row, index) => {
        let data = []
        data.push(index === combinedData.length - 1 ? '' : index + 1)
        data.push(row['vendor'])
        if (this.showProduct) {
          data.push(row['product'] ? row['product'] : '')
        }
        data.push({
          value: row['market_measure'],
          format: '#,###'
        })
        this.customHeaders.map(header => {
          data.push({
            value: row.metric_values[header][0],
            format: row.metric_values[header][1]
          })
        })
        downloadReportRows.push(data)
      })

      this.setDownloadReportData({
        name: 'Fintech Market Reports - ' + this.selectedVendorReport,
        technographic: this.selectedSolution.name,
        subTitle: this.reportSubTitle,
        filterText: this.selectedFiltersMessage,
        data: downloadReportRows
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.select-icon {
  color: lightgray;
  font-size: 1em;
  padding-left: 0.25em;
  cursor: pointer;
}
.firmographic-container {
  margin-top: 1em;
  thead tr th {
    border-bottom: 1px solid black;
  }
  tbody tr {
    line-height: 0.9em;
    td.name-column {
      white-space: nowrap;
      max-width: 18em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .report-footer {
    border-top: 1px solid black;
    font-weight: bold;
    background-color: #f4f4f4;
  }
  td.market_percent_chart {
    padding: 0;
    width: 35%;
    .progress {
      background-color: initial;
      padding-top: 1px;
      padding-left: 3em;
      height: 3em;
    }
  }
}
</style>
